<template>
  <div>
    <general-table
      ref="cashCallWalletTable"
      :api-url="apiUrl"
      :columns="fields"
      :table-options="tableOptions"
      :search-box="false"
      :selectable="false"
      :export-table="false"
    >
      <template #thead-top>
        <b-tr>

          <b-td
            class="border-0 p-0 pr-1 pb-2"
            colspan="1"
          >
            <b-btn
              v-if="$can('add', 'cashcall_balance')"
              @click="openAddBalanceDialog"
            >
              add balance
            </b-btn>
            <b-btn
              v-if="$can('update', 'cashcall_balance')"
              variant="primary"
              class="mx-1"
              @click="openWalletDialog"
            >
              edit balance
            </b-btn>

          </b-td>

        </b-tr>
      </template>
      <template #cell(id)="data">
        #{{ data.item.id }}
      </template>

    </general-table>
    <div>
      <b-modal
        id="editWallet"
        ref="editWallet"
        hide-footer
        centered
        title="edit Balance"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>

            <b-col md="12">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Amount"
                rules="required"
              >
                <b-form-group
                  label="Amount"
                  label-for="name"
                >
                  <b-form-input
                    id="amount"
                    v-model="amount"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Amount"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>
          </b-row></validation-observer>
        <div
          class="text-danger"
        >{{ errorMsg }}</div>

        <b-col
          cols="12"
          md="12"
          class="mt-1"
        >
          <b-button
            v-if="!loaderEditBalance"
            variant="primary"
            class="mr-1"
            @click.prevent="editWallet"
          >
            Save
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeModal()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loaderEditBalance"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-modal>
    </div>
    <div>
      <b-modal
        id="addBalance"
        ref="addBalance"
        hide-footer
        centered
        title="Add Balance"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>

            <b-col md="12">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Amount"
                rules="required"
              >
                <b-form-group
                  label="Amount"
                  label-for="name"
                >
                  <b-form-input
                    id="amount"
                    v-model="addBalanceAmount"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Amount"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>
          </b-row></validation-observer>
        <div class="text-danger">
          {{ errorMsg }}
        </div>
        <b-col
          cols="12"
          md="12"
          class="mt-1"
        >
          <b-button
            v-if="!loaderAddBalance"
            variant="primary"
            class="mr-1"
            @click.prevent="addBalance"
          >
            Save
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeAddBalanceModal()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loaderAddBalance"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from 'axios'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import AdminDashboardTable from '@/views/dashboard/admin-dashboard/components/AdminDashboardTable.vue'
import timeAgo from '@/global-compositions/timeAgo'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  setup() {
    const report = ref([])
    const { getValidationState } = formValidation()

    const amount = ref('')
    const addBalanceAmount = ref('')
    const loaderEditBalance = false
    const loaderAddBalance = false

    const fields = [
      { key: 'id', label: '#', sortable: true },

      { key: 'amount', label: 'Amount' },
      { key: 'action', label: 'Action' },
      {
        key: 'action_by',
        label: 'Action By',
      },
    ]
    const errorMsg = ref('')

    return {
      amount,
      loaderEditBalance,
      loaderAddBalance,
      addBalanceAmount,
      getValidationState,
      timeAgo,
      errorMsg,
      fields,
      report,
    }
  },
  computed: {
    apiUrl() {
      const base = 'cashacall/listTransaction'

      return base
    },
    downloadFile() {
      return `https://v2.zerocash.co/${this.apiUrl}`
    },
  },
  methods: {
    openWalletDialog() {
      this.amount = ''
      this.$refs.editWallet.show()
    },
    closeModal() {
      this.$refs.editWallet.hide()
      this.errorMsg = ''
    },
    openAddBalanceDialog() {
      this.addBalanceAmount = ''
      this.$refs.addBalance.show()
    },
    closeAddBalanceModal() {
      this.$refs.addBalance.hide()
      this.errorMsg = ''
    },
    editWallet() {
      this.loaderEditBalance = true

      axios.post('cashacall/updateAmount', { amount: this.amount }).then(res => {
        if (res.status === 200) {
          this.$refs.editWallet.hide()
          this.$toasted.show('Updated Successfully', {
            position: 'top-center',
            duration: 3000,
          })
          this.getdata()
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ooops!!! An Unexpected Internal server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else if (error.response.status === 422) {
          this.errorMsg = error.response.data.msg
        }
      }).finally(() => {
        this.loaderEditBalance = false
      })
    },
    addBalance() {
      this.loaderAddBalance = true

      axios.post('cashacall/addAmount', { amount: this.addBalanceAmount }).then(res => {
        if (res.status === 200) {
          this.$refs.addBalance.hide()
          this.$toasted.show('Added Successfully', {
            position: 'top-center',
            duration: 3000,
          })
          this.getdata()
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ooops!!! An Unexpected Internal server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else if (error.response.status === 422) {
          this.errorMsg = error.response.data.msg
        }
      }).finally(() => {
        this.loaderAddBalance = false
      })
    },
  },
}
</script>

<style>

</style>
